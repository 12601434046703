export const LOGO_HENRY_MOBILE = `https://assets.soyhenry.com/henry-landing/assets/logos/LOGO-HENRY-SIN-TEXTO.png`;
export const LOGO_HENRY_DESKTOP =
  "https://assets.soyhenry.com/logos/LOGO-HENRY-04.png";
export const SLACK_LINK = `${process.env.REACT_APP_API_URL}application/slack-link/`;
export const HENRY_FULL_STACK_PREP_COURSE =
  "https://rise.articulate.com/share/O0SjmUFcjx_3AXSA2lizESbHitgJIOpq#/";
export const HENRY_DATA_SCIENCE_PREP_COURSE =
  "https://rise.articulate.com/share/6nu4aSQjPQqC8q50odh5vONLu40TBo8C#/";
export const HENRY_COMERCIAL_SITE = "https://soyhenry.com";
export const NEXT_DATES_COHORTS_SHEETS =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vRO7D6JVj86V-tM3oWgiFqf3sZVNaQQ-prCIIukZ-CxYRoI5m3zEf4qjG3WTGxr9xCbnLPB1_aZRppm/pub?gid=0&single=true&output=tsv";
export const HENRY_ASSETS_URL = "https://assets.soyhenry.com/admissions-client";
export const HENRY_CHALLENGE_BASE_LINK =
  "https://github.com/checkpoint-soyhenry-org/";
export const SPREADSHEET_NEXT_COHORTS_DATES = {
  FS: "https://docs.google.com/spreadsheets/d/1Y2ydZZ6ZgBQpl-vm9gJi0kKXzGIPETpriZOaTBnY_Xc/edit#gid=0",
  DS: "https://docs.google.com/spreadsheets/d/1Y2ydZZ6ZgBQpl-vm9gJi0kKXzGIPETpriZOaTBnY_Xc/edit#gid=1840663781",
};

export const HENRY_LIVE_PREP_COURSE_LINK_FS =
  "https://us02web.zoom.us/j/86905299914?pwd=Ym94KyttSWErSlI1bTBhMHlMYkoxZz09";

export const HENRY_LIVE_PREP_COURSE_LINK_DS =
  "https://us02web.zoom.us/j/81924521839";
