import styled, { css } from "styled-components";
export const OpenMenu = styled.button`
  background: transparent;
  border: none;
  position: relative;
  width: 100%;
  cursor: pointer;
  @media (min-width: 700px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: rgba(117, 117, 117, 1);
  font-size: 12px;
  & :nth-child(odd) {
    display: none;
  }
  @media (max-width: 700px) {
    justify-content: flex-start;
    & :nth-child(even) {
      display: none;
    }
    & :nth-child(odd) {
      display: initial;
    }
  }
`;
export const Header = styled.header`
  max-width: 1120px;
  margin: auto;
  height: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 160px auto 90px;
  @media (max-width: 700px) {
    grid-template-columns: 50px 1fr 1fr;
  }
  @media (max-width: 600px) {
    padding: 0 0.9rem;
  }
`;
export const NavBarContainer = styled.nav`
  z-index: 100;
  width: 100%;
  height: 72px;
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.4);
`;
export const Avatar = styled.img`
  border-radius: 50%;
`;

export const NavBarItem = styled.li<{ selected?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 2rem;
  z-index: 100;
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    css`
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #2d3142d9;
    `}
`;

export const AvatarWrapper = styled.aside`
  position: relative;
  background: transparent;
  border: none;
  transition: 300ms ease-in-out;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const NavBarList = styled.ul<{ active?: boolean }>`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 700px) {
    z-index: -10;
    position: absolute;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    padding: 1rem 0;
    gap: 1rem;
    top: -50px;
    left: 0;
    height: fit-content;
    transition: top 300ms ease-in-out;
    box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16),
      0px 0px 1px rgba(0, 0, 0, 0.4);
    ${({ active }) =>
      active &&
      css`
        top: 52px;
      `};
  }
`;

export const NotificationIcon = styled.button<{ hasNotification?: boolean }>`
  background: transparent;
  border: none;
  position: relative;
  cursor: pointer;
  ${({ hasNotification }) =>
    hasNotification &&
    css`
      &:after {
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: rgba(255, 52, 92, 1);
        border: 1px solid #fff;
        top: 0;
        right: 0;
        position: absolute;
      }
    `}
`;

export const NotificationsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 700px) {
    justify-content: flex-end;
    padding: 0 20px;
  }
`;
