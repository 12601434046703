import {
  // Avatar,
  Header,
  LogoWrapper,
  NavBarContainer,
} from "./style";
import {
  LOGO_HENRY_DESKTOP,
  LOGO_HENRY_MOBILE,
} from "../../constant";
/* import BellIcon from "../../Icons/BellIcon"; */
/* import BurgerIcon from "../../Icons/BurgerIcon"; */
import { Link } from "react-router-dom";

export interface NavBarProps {
  hasNotifications?: boolean;
}
// eslint-disable-next-line no-empty-pattern
export default function NavBar({}: NavBarProps) {
  return (
    <NavBarContainer>
      <Header>
        <Link to="/">
          <LogoWrapper>
            <img
              src={LOGO_HENRY_MOBILE}
              alt="henry-logo"
              height={24}
              width={32}
              style={{ objectFit: "contain" }}
            />
            <img
              src={LOGO_HENRY_DESKTOP}
              alt="henry-logo"
              height={55}
              width={143}
              style={{ objectFit: "contain" }}
            />
          </LogoWrapper>
        </Link>
      </Header>
    </NavBarContainer>
  );
}
