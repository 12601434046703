import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  width: 1050px;
  grid-template-columns: auto 20.625rem;
  grid-column-gap: 24px;
  max-width: 100%;
  column-gap: 26px;
  box-sizing: border-box;
  @media (max-width: 60rem) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  left: 35%;
  margin-top: 0.625rem;
`;

export const PageContainer = styled.div`
  position: relative;
  padding-bottom: 5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 2rem;
  background-color: #f8f8f8;
  min-height: 100vh;
  @media (max-width: 75rem) {
    padding-bottom: 15.625rem;
  }
`;

export const PageContentContainer = styled.div`
  max-width: 70rem;
  margin: auto;
  padding: 24px 14.4px;
`;

export const CalendarTitle = styled.p`
  font-family: "Avenir book";
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.5rem;
`;
