import styled from "styled-components";

export const MessageWrapper = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color:#fff;
    width: 100%;
    max-width: 1070px;
    margin: 2rem auto;

`


export const Title = styled.h1`
font-size: 2rem;
color: #000;
font-weight: 700;
line-height: 2.375rem;

@media (max-width: 768px) {
    text-align: center;
}
`
    

export const Description = styled.p`
color: #999;
text-align:center;
font-size:1rem;
font-weight:500;
line-height:1.375rem;

a {
color:#6D8DFF;
}
`

export const RedirectButton = styled.a`
border-radius: 0.5rem;
background-color: #ffe840;
color: #ba8a19;
font-size: 0.875rem;
line-height: 1.25rem;
padding: 0.5rem;
transition: all 0.3s;

&:hover{
    background-color: #dac634;
    color: #ffffff;
}

&:active{
    background-color: #ba8a19;
    color: #ffffff;
}
`