
import NavBar from "../../components/NavBar";
import { PageContainer } from "../HomePage/style";
import { Description, MessageWrapper, RedirectButton, Title } from "./styles";

export default function RedirectPage() {


  return (
    <>
      <NavBar />
        <PageContainer>      
        <MessageWrapper>
        <img
          src="https://assets.soyhenry.com/admissions-client/shapes/starship.png"
          width={145}
          height={145}
          alt="henry-logo"
        />
          <Title>¡Lanzamos una nueva plataforma de Admisiones!</Title>
          <Description>Estamos felices de anunciar que hemos desarrollado una nueva y mejorada plataforma para el proceso de admisiones en Henry.
            Si ya has aprobado el Henry Challenge, por favor sigue las instrucciones recibidas previamente por correo electrónico para completar tu ingreso a la carrera.
            Si aún no has realizado el Henry Challenge, puedes crear una cuenta nueva en nuestra plataforma.
          </Description>
        <RedirectButton href={`https://www.auth.soyhenry.com/register?additionalInfo=%7B"utm_source"%3A"admissions_v1"%7D`} target="_blank" rel="noreferrer noopenner">Crear una nueva cuenta</RedirectButton>
        <Description>¡Te esperamos!</Description>
        <Description>Para cualquier duda, contáctanos a: <a target="_blank" rel='noreferrer noopenner' href={"mailto:admisiones@soyhenry.com"}>admisiones@soyhenry.com</a>.</Description>
        </MessageWrapper>
    </PageContainer></>
  );
}
