import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@soyhenry/commons";
import { BrowserRouter } from "react-router-dom";
import RedirectPage from "./pages/RedirectPage";


ReactDOM.render(
  <ThemeProvider>    
      <BrowserRouter>
            <RedirectPage/>
      </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);

reportWebVitals();
